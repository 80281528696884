import {
  GetTapTapCustomersPayload,
  TapTapGetCustomersCondition,
  TapTapQuickFilterView
} from '../types';
import { taptapBranchReleased } from './taptap-branch-released';
import { taptapCustomerField } from './taptap-fields';
import { taptapStatuses } from './taptap-form-fields';
import { taptapServiceType } from './taptap-service-type';

export const taptapCustomerInitialPayload: GetTapTapCustomersPayload = {
  search: '',
  pagination: {
    page: 1,
    pageSize: 50
  },
  filters: {
    logic: 'and',
    conditions: []
  },
  sort: [
    {
      field: 'created_at',
      direction: 'ASC'
    },
    {
      field: 'created_at',
      direction: 'ASC',
      referencedTable: 'customers_payments'
    }
  ]
};

export const quickFilterBranchesChoices: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    operator: 'in',
    value: [taptapBranchReleased.EARNSHAW]
  },
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    operator: 'in',
    value: [taptapBranchReleased.LPC]
  },
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    operator: 'in',
    value: [taptapBranchReleased.EXPERIENCE]
  }
];

const quickFilterForBuildConditions: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.STATUS,
    operator: 'in',
    value: [taptapStatuses.BUILDING, taptapStatuses.ON_QUEUE]
  },
  {
    field: taptapCustomerField.SERVICE_TYPE,
    operator: 'in',
    value: [taptapServiceType.FOR_BUILD]
  }
];

const quickFilterForPartsOut: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.SERVICE_TYPE,
    operator: 'in',
    value: [taptapServiceType.PARTS_OUT]
  }
];

const quickFilterForCashier: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.STATUS,
    operator: 'in',
    value: [taptapStatuses.FOR_PAYMENT, taptapStatuses.FOR_FULLPAYMENT]
  }
];

const quickFilterForLogistic: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.STATUS,
    operator: 'in',
    value: [
      taptapStatuses.READY_FOR_PICKUP,
      taptapStatuses.READY_FOR_DELIVERY,
      taptapStatuses.ONGOING_DELIVERY
    ]
  }
];

const quickFilterForReleasing: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.STATUS,
    operator: 'in',
    value: [
      taptapStatuses.FOR_PACK,
      taptapStatuses.PREPARING_ITEMS,
      taptapStatuses.DONE_PAYMENT,
      taptapStatuses.FOR_CHECKING
    ]
  }
];

const quickFilterCashierDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.LAST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.BRANCH_RELEASED,
  taptapCustomerField.STATUS,
  taptapCustomerField.TOTAL_AMOUNT,
  taptapCustomerField.PAID_AMOUNT,
  taptapCustomerField.BALANCE,
  taptapCustomerField.SOURCE,
  taptapCustomerField.SHIPPING_FEE,
  taptapCustomerField.IS_SHIPPING_FEE_PAID,
  taptapCustomerField.REMARKS,
  taptapCustomerField.CREATED_AT
];

const quickFilterForBuildDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.LAST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.TECH,
  taptapCustomerField.STATUS,
  taptapCustomerField.SOURCE,
  taptapCustomerField.COURIER,
  taptapCustomerField.BRANCH_RELEASED,
  taptapCustomerField.TRIAL_OS,
  taptapCustomerField.REMARKS,
  taptapCustomerField.CREATED_AT,
  taptapCustomerField.BUILD_TAG,
  taptapCustomerField.SALES_REP,
  taptapCustomerField.VIBER_TRANSACTION_TYPE,
  taptapCustomerField.ECOMM_TRANSACTION_TYPE,
  taptapCustomerField.FB_TRANSACTION_TYPE,
  taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
  taptapCustomerField.TYPE_OF_BUILD,
  taptapCustomerField.WITH_UPGRADE,
  taptapCustomerField.KIND_OF_SALES_TRANSFER
];

const quickFilterForPartsOutDefaultColumns = quickFilterForBuildDefaultColumns;

const quickFilterLogisticsDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.STATUS,
  taptapCustomerField.COURIER,
  taptapCustomerField.BALANCE,
  taptapCustomerField.CONTACT_NO,
  taptapCustomerField.ADDRESS,
  taptapCustomerField.SHIPPING_FEE,
  taptapCustomerField.IS_SHIPPING_FEE_PAID,
  taptapCustomerField.TRIAL_OS,
  taptapCustomerField.CREATED_AT,
  taptapCustomerField.VIBER_TRANSACTION_TYPE,
  taptapCustomerField.ECOMM_TRANSACTION_TYPE,
  taptapCustomerField.FB_TRANSACTION_TYPE,
  taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
  taptapCustomerField.BUILD_TAG,
  taptapCustomerField.TYPE_OF_BUILD,
  taptapCustomerField.WITH_UPGRADE,
  taptapCustomerField.KIND_OF_SALES_TRANSFER,
  taptapCustomerField.REMARKS
];

const quickFilterReleasingDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.LAST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.STATUS,
  taptapCustomerField.REMARKS,
  taptapCustomerField.CONTACT_NO,
  taptapCustomerField.ADDRESS,
  taptapCustomerField.CREATED_AT,
  taptapCustomerField.SOURCE,
  taptapCustomerField.FB_TRANSACTION_TYPE,
  taptapCustomerField.COURIER,
  taptapCustomerField.SERVICE_TYPE,
  taptapCustomerField.TOTAL_AMOUNT,
  taptapCustomerField.PAID_AMOUNT,
  taptapCustomerField.BALANCE,
  taptapCustomerField.SHIPPING_FEE,
  taptapCustomerField.IS_SHIPPING_FEE_PAID,
  taptapCustomerField.BRANCH_RELEASED,
  taptapCustomerField.KIND_OF_SALES_TRANSFER,
  taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
  taptapCustomerField.WITH_UPGRADE
];

export const quickFilterViewsChoices: TapTapQuickFilterView[] = [
  {
    id: 'for_build',
    label: 'FOR BUILD',
    conditions: quickFilterForBuildConditions,
    defaultColumns: quickFilterForBuildDefaultColumns
  },
  {
    id: 'for_parts_out',
    label: 'FOR PARTS OUT',
    conditions: quickFilterForPartsOut,
    defaultColumns: quickFilterForPartsOutDefaultColumns
  },
  {
    id: 'cashier',
    label: 'CASHIER',
    conditions: quickFilterForCashier,
    defaultColumns: quickFilterCashierDefaultColumns
  },
  {
    id: 'logistics',
    label: 'LOGISTICS',
    conditions: quickFilterForLogistic,
    defaultColumns: quickFilterLogisticsDefaultColumns
  },
  {
    id: 'releasing',
    label: 'RELEASING',
    conditions: quickFilterForReleasing,
    defaultColumns: quickFilterReleasingDefaultColumns
  }
];
