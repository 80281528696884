import React from 'react';
import { Navigate } from 'react-router-dom';
import { isNil } from 'lodash';

import { MainLayout, DashboardLayout } from 'src/layouts';

import DashboardView from 'src/views/reports/DashboardView';

import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import SettingsView from 'src/views/settings/SettingsView';

import AccountView from 'src/views/account';

import AddTransactionView from 'src/views/transactions/AddTransaction';
import AddCustomerView from 'src/views/customer/customer-add';
import TransactionDetailsView from './views/transactions/view-transaction-details';
import TransactionPDF from './views/transactions/TransactionPDF';
import TransactionSlipView from './views/transactions/TransactionSlip';
import TransactionView from './views/transactions';

import CustomerListView from 'src/views/customer/customer-list';
import CustomerProfileView from 'src/views/customer/customer-profile';

import ProductsView from 'src/views/product/product-list';
import AddProductView from 'src/views/product/product-add';
import AddProductListingView from 'src/views/product/product-listing-add';
import ProductView from './views/product/product-details';
import ProductListingView from './views/product/product-listing-view';
import ProductInformationView from './views/product/information';

import CategoriesView from './views/categories';
import CategoryView from './views/categories/CategoryView';
import CategoriesAddView from './views/categories/AddCategory';

import ManufacturersView from './views/manufacturers';
import ManufacturerView from './views/manufacturers/ManufacturerView';
import ManufacturersAddView from './views/manufacturers/AddManufacturer';

import SuppliersAddView from './views/suppliers/AddSupplier';
import SupplierView from './views/suppliers/SupplierView';
import SuppliersView from './views/suppliers';

import SalesList from './views/sales';

import RolesAndPermissionsView from './views/roles-permissions';
import RoleDetailView from './views/roles-permissions/role';
import PermissionDetailView from './views/roles-permissions/permission';

import UsersView from './views/users';
import UserDetailView from './views/users/detail';
import CreateUserView from './views/users/create';

import MaintenanceView from './maintenances';

import AuditView from './views/audit';

import InventoryView from './views/inventory';
import AvailableInventoryView from './views/available-inventory';
import CreateQuotationView from './views/quotation/create-quotation';
import { SMSBlast } from './views/sms-blast';

import {
  AddPcPackageView,
  PcPackageDetailsView,
  PcPackageListView
} from './views/pc-package';

import {
  GalleryListView,
  AddGalleryView,
  GalleryDetailsView
} from './views/gallery';

import { Permission, PermissionApiName, UserBasicInfo } from './types';

import { EcomOrdersListView, EcomOrderDetailsView } from './views/ecom';
import { EcommBannerView } from './views/ecomm-banner';

import {
  AddFlashDealsView,
  FlashDealsListView,
  FlashDealDetailsView
} from './views/flash-deals';
import PcwUserList from './views/pcw-user/user-list';
import UserAccountProfile from './views/pcw-user/user-profile';
import OptionListView from './views/options';
import OptionView from './views/options/OptionView';
import BranchList from './views/branch';
import { BranchView } from './views/branch/component';
import { multiBranchFeat } from './constants/feature-toggle';
import Minigame from './views/minigame';
import UserAccountAddress from './views/pcw-user/user-address';
import EventCodeGenerator from './views/event-code';
import { SMSBlastV2 } from './views/sms-blast-v2';
import KachiPointsPage from './views/kachi-points';
import KachiPointsHistory from './views/kachi-points/KachiHistory';
import { StockTransferView } from './views/stock-transfer';
import { ProductRecentOutOfStockView } from './views/product/out-of-stock/out-of-stocks-product';
import { PriceListView } from './views/price-list';
import DecayingStocksView from './views/decaying-stocks';
import { FastMovingStockView } from './views/fast-moving-products';
import { EcommSavedBuildsView } from './views/ecomm-builds';
import { TransferStockLogsView } from './views/stock-transfer/transfer-stocks-log';
import { BuildDetailsPage } from './views/ecomm-builds/details';
import { BlogView } from './views/blog';
import { CreateBlogView } from './views/blog/create';
import { BlogDetailsView } from './views/blog/details';
import BlogCategoryView from './views/blog-category';
import InventoryAuditView from './views/inventory-audits';
import WelcomePage from './views/welcome-page';
import InventoryAuditLogsView from './views/inventory-audit-logs';
import InventoryAuditLogsDetailsView from './views/inventory-audit-logs/inventory-logs-details';
import GamesListView from './views/games';
import PCBundleListView from './views/pc-bundle';
import CreatePCBundle from './views/pc-bundle/CreatePCBundle';
import PCBundleDetails from './views/pc-bundle/PCBundleDetails';
import EditBundleComponents from './views/pc-bundle/EditBundleComponents';
import TapTapView from './views/taptap-customer';
import TaptapCustomerDetails from './views/taptap-customer/TaptapCustomerDetails';
import AddTapTapCustomer from './views/taptap-customer/AddTapTapCustomer';
import { PromotionalsPage } from './views/promotional';
import { PromotionalCreatePage } from './views/promotional/create';
import { PromotionalDetailPage } from './views/promotional/details';

interface PartialRouteObject {
  caseSensitive?: boolean;
  children?: RouteChildren[];
  element?: React.ReactNode;
  path?: string;
}

interface RouteChildren extends PartialRouteObject {
  exact?: boolean;
}

const permissionsChecker = (
  permissionNameArg: PermissionApiName,
  permissionsArg?: Permission[]
) => {
  const index = permissionsArg?.findIndex((x) => x.name === permissionNameArg);
  return !isNil(index) && index > -1;
};

const rolesPermissionRoute = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  if (permissionsChecker('edit_permissions_roles', permissionsArg)) {
    return [
      { path: 'roles-permissions', element: <RolesAndPermissionsView /> },
      { path: 'role/:id', element: <RoleDetailView /> },
      { path: 'permission/:id', element: <PermissionDetailView /> }
    ];
  }
  return [];
};

const manageUsersRoute = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  if (permissionsChecker('manage_users', permissionsArg)) {
    return [
      { path: 'users', element: <UsersView /> },
      { path: 'user/:id', element: <UserDetailView /> },
      { path: 'user/add', element: <CreateUserView /> }
    ];
  }
  return [];
};

const manageBranchesRoute = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  if (
    permissionsChecker('can_edit_branches', permissionsArg) &&
    multiBranchFeat
  ) {
    return [
      { path: 'branches', element: <BranchList /> },
      { path: 'branches/:id', element: <BranchView /> }
    ];
  }
  return [];
};

const editOptions = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  if (permissionsChecker('can_edit_options', permissionsArg)) {
    return [
      { path: 'options', element: <OptionListView /> },
      { path: 'options/:id', element: <OptionView /> }
    ];
  }
  return [];
};

const salesRoute = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  let allowedSalesRoutes: PartialRouteObject[] = [];
  if (permissionsChecker('view_sales', permissionsArg)) {
    allowedSalesRoutes.push({
      path: 'sales',
      element: <SalesList />
    });
    // return [{ path: 'sales', element: <SalesList /> }];
  }
  if (permissionsChecker('view_ecom_user_account', permissionsArg)) {
    allowedSalesRoutes.push({
      path: 'pcw-user',
      element: <PcwUserList />
    });
  }
  if (permissionsChecker('view_ecom_user_account', permissionsArg)) {
    allowedSalesRoutes.push({
      path: 'pcw-user/user-profile/:id',
      element: <UserAccountProfile />
    });
  }
  if (permissionsChecker('view_ecom_user_account', permissionsArg)) {
    allowedSalesRoutes.push({
      path: 'pcw-user/user-address/:userId/:addressId',
      element: <UserAccountAddress />
    });
  }
  if (permissionsChecker('can_view_pricelist', permissionsArg)) {
    allowedSalesRoutes.push({
      path: 'price-list',
      element: <PriceListView />
    });
  }
  return allowedSalesRoutes;
};

const accountingRoute = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;

  if (permissionsChecker('can_see_available_inventory', permissionsArg)) {
    return [
      { path: 'available-inventory', element: <AvailableInventoryView /> }
    ];
  }

  return [];
};

const marketingRoutes = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  let allowedMarketingRoutes: PartialRouteObject[] = [];

  if (permissionsChecker('can_view_blog', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'blog',
      element: <BlogView />
    });
  }

  if (permissionsChecker('can_view_blog', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'blog/details/:id',
      element: <BlogDetailsView />
    });
  }

  if (permissionsChecker('can_create_blog', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'blog/create',
      element: <CreateBlogView />
    });
  }

  if (permissionsChecker('can_view_blog_category', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'blog-category',
      element: <BlogCategoryView />
    });
  }

  if (permissionsChecker('view_ecomm_banners', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'ecomm-banner',
      element: <EcommBannerView />
    });
  }
  if (permissionsChecker('can_see_flash_deals', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'flash-deals',
      element: <FlashDealsListView />
    });
    allowedMarketingRoutes.push({
      path: 'flash-deals/view/:id',
      element: <FlashDealDetailsView />
    });
  }
  if (permissionsChecker('can_add_flash_deals', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'flash-deals/add',
      element: <AddFlashDealsView />
    });
  }
  if (permissionsChecker('can_create_pc_package', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'pc-package/add',
      element: <AddPcPackageView />
    });
  }
  if (permissionsChecker('can_sms_blast', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'sms-blast',
      element: permissionsChecker(
        'feature_toggle_sms_blast_v2',
        permissionsArg
      ) ? (
        <SMSBlastV2 />
      ) : (
        <SMSBlast />
      )
    });
  }
  if (permissionsChecker('view_code_event', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'code-generator',
      element: <EventCodeGenerator />
    });
  }

  if (permissionsChecker('view_minigame', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'minigame',
      element: <Minigame />
    });
  }

  //TODO: add here
  if (permissionsChecker('can_create_promotional', permissionsArg)) {
    allowedMarketingRoutes.push({
      path: 'promotional',
      element: <PromotionalsPage />
    });

    allowedMarketingRoutes.push({
      path: 'promotional/create',
      element: <PromotionalCreatePage />
    });

    allowedMarketingRoutes.push({
      path: 'promotional/details/:id',
      element: <PromotionalDetailPage />
    });
  }

  //TODO: to be changed permission
  if (permissionsChecker('view_kachi_points', permissionsArg)) {
    allowedMarketingRoutes.push(
      {
        path: 'kachi-points',
        element: permissionsChecker(
          'feature_toggle_kachi_points',
          permissionsArg
        ) ? (
          <KachiPointsPage />
        ) : null
      },
      {
        path: 'kachi-points-history/:id',
        element: <KachiPointsHistory />
      }
    );
  }
  if (permissionsChecker('can_view_ecomm_saved_builds', permissionsArg)) {
    allowedMarketingRoutes.push(
      {
        path: 'saved-builds',
        element: <EcommSavedBuildsView />
      },
      {
        path: 'saved-builds/details/:id',
        element: <BuildDetailsPage />
      }
    );
  }
  return allowedMarketingRoutes;
};

const listingRoutes = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  let allowedListingRoutes: PartialRouteObject[] = [];

  allowedListingRoutes.push({
    path: 'products-listings/:id',
    element: <ProductListingView />
  });

  if (permissionsChecker('can_add_listing', permissionsArg)) {
    allowedListingRoutes.push({
      path: 'products-listings/add',
      element: <AddProductListingView />
    });
  }

  return allowedListingRoutes;
};

const InventoryRoutes = (userInfo?: UserBasicInfo) => {
  const permissionsArg = userInfo?.all_permissions;
  let allowedInventoryRoutes: PartialRouteObject[] = [];

  if (permissionsChecker('can_inventory_audit', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'inventory-audit',
      element: <InventoryAuditView />
    });
  }

  if (permissionsChecker('can_view_audit_logs', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'inventory-audit-logs',
      element: <InventoryAuditLogsView />
    });
  }

  if (permissionsChecker('can_view_audit_logs', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'inventory-audit-logs/:id',
      element: <InventoryAuditLogsDetailsView />
    });
  }

  if (permissionsChecker('view_recent_out_of_stock', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'out-of-stocks',
      element: <ProductRecentOutOfStockView />
    });
  }

  if (permissionsChecker('can_view_decaying_stocks', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'decaying-stocks',
      element: <DecayingStocksView />
    });
  }

  if (permissionsChecker('can_view_fast_moving_products', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'fast-moving-products',
      element: <FastMovingStockView />
    });
  }

  if (permissionsChecker('view_stock_transfer', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'transfer-stock',
      element: <StockTransferView />
    });
  }

  if (permissionsChecker('can_view_transfer_stocks_logs', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'transfer-stock-logs',
      element: <TransferStockLogsView />
    });
  }

  if (permissionsChecker('can_add_product', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'products/add',
      element: <AddProductView />
    });
  }

  if (permissionsChecker('can_edit_product_information', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'products/information/:id',
      element: <ProductInformationView />
    });
  }

  if (permissionsChecker('can_edit_category', permissionsArg)) {
    allowedInventoryRoutes.push({
      path: 'categories/:id',
      element: <CategoryView />
    });
  }

  return allowedInventoryRoutes;
};

const routes = (userInfo?: UserBasicInfo) => [
  {
    path: 'app',
    element: userInfo ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'customer-taptap', element: <TapTapView /> },
      { path: 'customer-taptap/add', element: <AddTapTapCustomer /> },
      { path: 'customer-taptap/:id', element: <TaptapCustomerDetails /> },

      { path: 'account', element: <AccountView /> },

      { path: 'audits', element: <AuditView /> },

      { path: 'customers', element: <CustomerListView /> },
      { path: 'customers/add', element: <AddCustomerView /> },
      { path: 'customers/:id', exact: true, element: <CustomerProfileView /> },

      { path: 'transactions', element: <TransactionView /> },
      { path: 'transaction/add', exact: true, element: <AddTransactionView /> },
      {
        path: 'transaction/:transaction_no',
        exact: true,
        element: <TransactionDetailsView />
      },
      {
        path: 'transaction/print',
        exact: true,
        element: <TransactionPDF />
      },
      { path: 'transaction-slip', element: <TransactionSlipView /> },

      { path: 'categories', element: <CategoriesView /> },
      { path: 'categories/add', element: <CategoriesAddView /> },

      { path: 'ecom-orders', element: <EcomOrdersListView /> },
      { path: 'ecom-order/:orderRefNo', element: <EcomOrderDetailsView /> },

      { path: 'manufacturers', element: <ManufacturersView /> },
      { path: 'manufacturers/:id', element: <ManufacturerView /> },
      { path: 'manufacturers/add', element: <ManufacturersAddView /> },

      { path: 'dashboard', element: <DashboardView /> },
      { path: 'pc-package', element: <PcPackageListView /> },
      { path: 'pc-package/view/:id', element: <PcPackageDetailsView /> },

      { path: 'gallery-list', element: <GalleryListView /> },
      { path: 'gallery/view/:id', element: <GalleryDetailsView /> },
      { path: 'gallery/add', element: <AddGalleryView /> },

      { path: 'products', element: <ProductsView /> },
      { path: 'products/:id', element: <ProductView /> },

      { path: 'suppliers', element: <SuppliersView /> },
      { path: 'suppliers/:id', element: <SupplierView /> },
      { path: 'suppliers/add', element: <SuppliersAddView /> },

      { path: 'welcome-page', element: <WelcomePage /> },

      ...InventoryRoutes(userInfo),
      ...listingRoutes(userInfo),
      ...marketingRoutes(userInfo),
      ...accountingRoute(userInfo),
      ...salesRoute(userInfo),
      ...rolesPermissionRoute(userInfo),
      ...manageUsersRoute(userInfo),
      ...manageBranchesRoute(userInfo),
      ...editOptions(userInfo),

      //  PC Bundle Routes
      { path: 'games', element: <GamesListView /> },
      { path: 'pc-bundles', element: <PCBundleListView /> },
      { path: 'pc-bundles/add', element: <CreatePCBundle /> },
      { path: 'pc-bundles/:id', element: <PCBundleDetails /> },
      { path: 'pc-bundles/components/:id', element: <EditBundleComponents /> },

      //TODO: rma is deprecated, remove later
      // { path: 'rma', element: <RmaView /> },
      // { path: 'rma/:id', element: <RmaDetailsView /> },
      // { path: 'rma/add', element: <CreateRmaView /> },

      { path: 'quotation', element: <CreateQuotationView /> },

      { path: 'inventory', element: <InventoryView /> },

      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: !userInfo ? <MainLayout /> : <Navigate to="/app/welcome-page" />,
    children: [
      { path: 'maintenance', element: <MaintenanceView /> },
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/welcome-page" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
