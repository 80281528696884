import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { CommonAxiosResponse } from 'src/types';
import Api from 'src/services/ApiService';
import {
  CreatePromoResponse,
  CreatePromoPayload,
  UpdatePromotionalDetailsResponse,
  UpdatePromotionalDetailsPayload,
  UpdatePromotionalContentPayload,
  GetPromotionalListingsResponse,
  GetPromotionalListingsPayload,
  PromotionalProductsPayload,
  GetPromotionalProductsResponse,
  GetPromoDetailsResponse
} from './types';

export const createPromotionalThunk = createAsyncThunk<
  CommonAxiosResponse<CreatePromoResponse>,
  CreatePromoPayload,
  { state: RootState }
>('promotional/createPromotionalThunks', async (data) => {
  const response = await Api.post('/ecomm/internal/promotional/create', data);
  return response;
});

export const updatePromotionalDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<UpdatePromotionalDetailsResponse>,
  UpdatePromotionalDetailsPayload,
  { state: RootState }
>('promotional/updatePromotionalDetailsThunk', async (data) => {
  const { id, ...params } = data || {};
  const response = await Api.patch(
    `/ecomm/internal/promotional/details/update/${id}`,
    params
  );
  return response;
});

export const updatePromotionalContentThunk = createAsyncThunk<
  CommonAxiosResponse<CreatePromoResponse>,
  UpdatePromotionalContentPayload,
  { state: RootState }
>('promotional/updatePromotionalContentThunk', async (data) => {
  const { id, ...content } = data;
  const response = await Api.patch(
    `/ecomm/internal/promotional/content/update/${id}`,
    content
  );
  return response;
});

export const getPromotionalListingsThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromotionalListingsResponse>,
  GetPromotionalListingsPayload | undefined,
  { state: RootState }
>('promotional/getPromotionalListingsThunk', async (data) => {
  const response = await Api.get(
    `/ecomm/internal/promotional/listings/get`,
    data
  );
  return response;
});

export const getPromotionalDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromoDetailsResponse>,
  string,
  { state: RootState }
>('promotional/getPromotionalDetailsThunk', async (promo_id) => {
  const response = await Api.get(
    `/ecomm/internal/promotional/details/get/${promo_id}`
  );
  return response;
});

export const getPromotionalProductThunk = createAsyncThunk<
  CommonAxiosResponse<GetPromotionalProductsResponse>,
  PromotionalProductsPayload,
  { state: RootState }
>('promotional/getPromotionalProductThunk', async (data) => {
  const response = await Api.get(
    `/ecomm/internal/promotional/product/get`,
    data
  );
  return response;
});
