import React, { FC, memo, useEffect, useState } from 'react';
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { Pagination } from '@material-ui/lab';
import usePromotional from 'src/hooks/promotional/use-promotional';
import { SearchResultNotFound } from 'src/components';
import { useNavigate } from 'react-router';

const useStyles = makeStyles({
  card: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 10,
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  }
});

interface Props {}

const Component: FC<Props> = () => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    isLoading,
    promoListing,
    errMsg,
    getPromoListings
  } = usePromotional();

  const [searchPromo, setSearchPromo] = useState<string>();
  const [page_num, setPageNum] = useState<number>(1);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchPromo(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      //reset page state
      setPageNum(1);
      getPromoListings({ keyword: searchPromo });
    }
  };

  const handleChangePage = (page_num: number) => {
    setPageNum(page_num);
    getPromoListings({ keyword: searchPromo, page: page_num });
  };

  const onHandleUpdate = (id?: number) => {
    navigate(`/app/promotional/details/${id}`);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    getPromoListings();
  }, [getPromoListings]);

  return (
    <Box>
      <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
        <TextField
          id="search-promo"
          label="Search Promo"
          value={searchPromo}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Paper>
      {promoListing?.data && promoListing?.data.length >= 1 && !isLoading ? (
        <>
          <Box
            mt={5}
            height={isMobile ? '60vh' : '50vh'}
            style={{ overflowY: 'scroll' }}
          >
            {promoListing?.data?.map((item, index) => (
              <Card
                className={classes?.card}
                key={index}
                onClick={() => onHandleUpdate(item?.id)}
              >
                <Box>
                  <Typography variant="h4">{item?.title}</Typography>
                  {isMobile ? (
                    <Typography
                      variant="h4"
                      style={{
                        marginTop: '1rem',
                        color: item?.is_published ? '#5cb85c' : '#ff2c2c'
                      }}
                    >
                      {item?.is_published ? 'Published' : 'Unpublished'}
                    </Typography>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {!isMobile ? (
                    <Typography
                      variant="h4"
                      style={{
                        color: item?.is_published ? '#5cb85c' : '#ff2c2c'
                      }}
                    >
                      {item?.is_published ? 'Published' : 'Unpublished'}
                    </Typography>
                  ) : null}
                  <IconButton style={{ marginLeft: '1rem' }}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Box>
              </Card>
            ))}
          </Box>
          <Box
            style={{
              padding: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Pagination
              count={promoListing?.meta?.last_page}
              page={page_num || promoListing?.meta?.current_page}
              onChange={(_, page_num) => handleChangePage(page_num)}
            />
          </Box>
        </>
      ) : isLoading || !promoListing ? (
        <LinearProgress />
      ) : (
        <SearchResultNotFound title={errMsg} onHandleRefresh={refreshPage} />
      )}
    </Box>
  );
};

export const PromoListings = memo(Component);
