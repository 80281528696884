import * as thunks from './thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetTapTapCustomersPayload,
  TapTapColumn,
  TapTapCustomerData,
  TapTapCustomerPayment,
  TapTapFiltersV2,
  TapTapProfile
} from './types';
import {
  currentTapTapVersion,
  taptapCustomerInitialPayload
} from './constants';
import { initialTaptapTableColumns } from './constants/taptap-table-columns';

type State = {
  taptapProfiles: TapTapProfile[];

  isTapTapCustomersLoading: boolean;
  taptapCustomers: TapTapCustomerData[];
  taptapGetCustomersPayload: GetTapTapCustomersPayload;
  taptapColumns: TapTapColumn[];

  taptapCustomerMaxCount: number;
  isNextPageLoading: boolean;
  lastPageReached: boolean;

  taptapVersion?: number;
};

const initialState: State = {
  isTapTapCustomersLoading: false,
  taptapCustomers: [],
  taptapProfiles: [],
  taptapGetCustomersPayload: taptapCustomerInitialPayload,
  taptapColumns: initialTaptapTableColumns,

  taptapCustomerMaxCount: 0,
  isNextPageLoading: false,
  lastPageReached: false,
  taptapVersion: currentTapTapVersion
};

const slice = createSlice({
  name: 'taptapCustomer',
  initialState,
  reducers: {
    updateSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.taptapGetCustomersPayload.search = payload || '';
    },
    insertNewTaptapCustomers: (
      state,
      { payload }: PayloadAction<TapTapCustomerData>
    ) => {
      state.taptapCustomers.unshift({
        ...payload,
        customers_payments: payload?.customers_payments || []
      });
    },
    setTaptapCustomers: (
      state,
      { payload }: PayloadAction<TapTapCustomerData[]>
    ) => {
      state.taptapCustomers = payload;
    },
    updateTaptapCustomer: (
      state,
      { payload }: PayloadAction<TapTapCustomerData>
    ) => {
      const index = state.taptapCustomers.findIndex(
        (customer) => customer.id === payload.id
      );
      if (index !== -1) {
        if (!state.taptapCustomers[index]?.is_deleted && payload.is_deleted) {
          // Meaning this is an update but got soft deleted during update
          // Dating nasa list pero na soft delete na
          state.taptapCustomers = state.taptapCustomers.filter(
            (item) => item.id !== payload.id
          );
        } else {
          // Do the usual update
          state.taptapCustomers[index] = {
            ...payload,
            customers_payments:
              state.taptapCustomers[index].customers_payments || []
          };
        }
      }
    },
    removeTaptapCustomer: (
      state,
      { payload }: PayloadAction<TapTapCustomerData>
    ) => {
      state.taptapCustomers = state.taptapCustomers.filter(
        (item) => item.id !== payload.id
      );
    },

    //  Start of customer payment related reducers
    insertNewTaptapCustomerPayment: (
      state,
      { payload }: PayloadAction<TapTapCustomerPayment>
    ) => {
      const customerIndex = state.taptapCustomers?.findIndex(
        (x) => x.id === payload.customer_id
      );
      if (customerIndex !== -1) {
        state.taptapCustomers[customerIndex].customers_payments?.push(payload);
      }
    },
    updateTaptapCustomerPaymentDetails: (
      state,
      { payload }: PayloadAction<TapTapCustomerPayment>
    ) => {
      const customerIndex = state.taptapCustomers.findIndex(
        (customer) => customer.id === payload?.customer_id
      );
      // Check if customer exist
      if (customerIndex !== -1) {
        const customer = state.taptapCustomers[customerIndex];

        if (
          customer?.customers_payments &&
          customer?.customers_payments.length > 0
        ) {
          const customerPaymentIndex = customer.customers_payments.findIndex(
            (payment) => payment.id === payload?.id
          );
          if (customerPaymentIndex !== -1) {
            if (
              !customer.customers_payments[customerPaymentIndex]?.is_deleted &&
              payload.is_deleted
            ) {
              // Meaning this is an update but got soft deleted during update
              // Dating nasa list pero na soft delete na
              customer.customers_payments = customer.customers_payments.filter(
                (item) => item.id !== payload.id
              );
            } else {
              customer.customers_payments[customerPaymentIndex] = payload;
            }
          }
        }
      }
    },
    // DO THIS WHEN SERVER DELETED THE PAYMENT AS IN DELETED NOT SOFT DELETED
    removeTaptapCustomerPayment: (
      state,
      { payload }: PayloadAction<TapTapCustomerPayment>
    ) => {
      const paymentId = payload?.id;

      if (payload?.id) {
        const customer = state.taptapCustomers.find((customer) =>
          customer?.customers_payments?.some(
            (payment) => payment.id === paymentId
          )
        );

        if (customer && customer?.customers_payments) {
          // Remove the payment with the matching paymentId
          customer.customers_payments = customer?.customers_payments.filter(
            (payment) => payment.id !== paymentId
          );
        }
      }
    },
    /**
     * START OF USING FILTER
     */
    updateTaptapCustomerFilter: (
      state,
      { payload }: PayloadAction<TapTapFiltersV2>
    ) => {
      state.taptapGetCustomersPayload.filters = payload;
    },

    /**
     * START OF COLUMN
     */
    updateTaptapColumns: (
      state,
      { payload }: PayloadAction<TapTapColumn[]>
    ) => {
      state.taptapColumns = payload;
    },
    resetTaptapColumns: (state) => {
      state.taptapColumns = initialState.taptapColumns;
    },
    resetAll: (state) => {
      // SOLID RESET AHHAHAHA
      state.isTapTapCustomersLoading = initialState.isTapTapCustomersLoading;
      state.taptapCustomers = initialState.taptapCustomers;
      state.taptapProfiles = initialState.taptapProfiles;
      state.taptapGetCustomersPayload = initialState.taptapGetCustomersPayload;
      state.taptapColumns = initialState.taptapColumns;

      state.taptapCustomerMaxCount = initialState.taptapCustomerMaxCount;
      state.isNextPageLoading = initialState.isNextPageLoading;
      state.lastPageReached = initialState.lastPageReached;
      state.taptapVersion = initialState.taptapVersion;
    },
    setTapTapVersion: (state, { payload }: PayloadAction<number>) => {
      state.taptapVersion = payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.taptapGetProfilesThunk.fulfilled,
      (state, { payload }) => {
        state.taptapProfiles = payload.data || [];
      }
    );
    builder.addCase(thunks.taptapFetchCustomersThunk.pending, (state) => {
      state.taptapGetCustomersPayload.pagination.page = 1;
      state.lastPageReached = false;
      state.taptapCustomerMaxCount = 0;
      state.isTapTapCustomersLoading = true;
    });
    builder.addCase(
      thunks.taptapFetchCustomersThunk.fulfilled,
      (state, { payload }) => {
        state.isTapTapCustomersLoading = false;
        state.taptapCustomers = payload?.data || [];
        state.taptapCustomerMaxCount = payload?.count || 0;
      }
    );
    builder.addCase(thunks.taptapFetchCustomersThunk.rejected, (state) => {
      state.isTapTapCustomersLoading = false;
    });

    builder.addCase(
      thunks.taptapFetchCustomersNextPageThunk.pending,
      (state) => {
        state.isNextPageLoading = true;
      }
    );
    builder.addCase(
      thunks.taptapFetchCustomersNextPageThunk.fulfilled,
      (state, { payload }) => {
        state.isNextPageLoading = false;
        state.taptapCustomerMaxCount = payload?.count || 0;
        state.taptapCustomers = [
          ...state.taptapCustomers,
          ...(payload.data ?? [])
        ];

        if (
          (state.taptapCustomers || []).length >= state.taptapCustomerMaxCount
        ) {
          state.lastPageReached = true;
        }

        if (
          (state.taptapCustomers || []).length < state.taptapCustomerMaxCount
        ) {
          ++state.taptapGetCustomersPayload.pagination.page;
        }
      }
    );
    builder.addCase(
      thunks.taptapFetchCustomersNextPageThunk.rejected,
      (state) => {
        state.isNextPageLoading = false;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...thunks,
  ...slice.actions
};
