import React, { FC, memo } from 'react';
import {
  Box,
  colors,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
//TODO: add later if pagination is added on response
// import { Pagination } from '@material-ui/lab';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { GetInventoryAuditLogsListingsData } from 'src/redux/slices/inventory-audit-logs';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px'
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '1rem',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold'
  },
  statusIcon: {
    marginRight: '8px'
  },
  tableRow: {
    cursor: 'pointer', // Makes the row look clickable
    '&:hover': {
      backgroundColor: '#c0c0c0' // Adds a hover effect
    }
  }
});

interface Props {
  auditListing?: GetInventoryAuditLogsListingsData[];
}

const component: FC<Props> = ({ auditListing }) => {
  const classes = useStyles();

  return (
    <Box>
      <PerfectScrollbar style={{ marginTop: '1rem', overflow: 'scrolllX' }}>
        <TableContainer
          component={Paper}
          style={{
            minHeight: '50vh',
            maxHeight: '50vh',
            overflow: 'auto',
            marginBottom: '3rem'
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHeader}
                  style={{ width: '5%' }}
                />
                <TableCell className={classes.tableHeader}>IAL No.</TableCell>
                <TableCell className={classes.tableHeader}>
                  Audited By
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Audited Date
                </TableCell>
                <TableCell className={classes.tableHeader}>Category</TableCell>
                <TableCell className={classes.tableHeader}>Branch</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditListing?.map((listing) => (
                <AlternatingColorTableRow
                  key={listing?.ial_no}
                  className={classes.tableRow}
                >
                  <TableCell>
                    {/* if true the item has no issues */}
                    {listing?.with_issues ? (
                      <CheckCircleIcon style={{ color: colors?.green[500] }} />
                    ) : (
                      <Tooltip title="This IAL No. has audit issue">
                        <ErrorOutlineIcon color="secondary" />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {listing?.ial_no ? (
                      <LinkComponent
                        openInNewTab
                        href={`/app/inventory-audit-logs/${listing?.ial_no}`}
                        title={listing?.ial_no}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>{listing?.audited_by}</TableCell>
                  <TableCell>{listing?.audited_date}</TableCell>
                  <TableCell>{listing?.category}</TableCell>
                  <TableCell>{listing?.branch}</TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      {/* TODO:add later if pagination is added on response */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination count={5} page={3} onChange={() => {}} />
      </Box> */}
    </Box>
  );
};

export const AuditLogsListing = memo(component);
