import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { taptapFetchCustomers, taptapGetProfiles } from './services';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import {
  GetTapTapCustomersPayload,
  TapTapCustomerData,
  TapTapProfile
} from './types';
import { taptapCustomerField } from './constants/taptap-fields';
import { cloneDeep } from 'lodash';

const getCustomersQueryBuilder = ({
  payload,
  maxRecordCount = 0
}: {
  payload: GetTapTapCustomersPayload;
  maxRecordCount?: number;
}) => {
  const searchKeyword = payload.search?.trim();
  const logic = payload.filters?.logic || 'and';
  const conditions = payload.filters?.conditions || [];

  // Pagination logic
  const pagination = payload.pagination;
  const currentPage = pagination.page;
  // const nextPage = currentPage + 1;
  const pageSize = pagination?.pageSize;

  // sorting related
  const sortingPayload = payload.sort || [];

  let query = taptapFetchCustomers();

  if (searchKeyword) {
    const conditions = [
      `${taptapCustomerField.FIRST_NAME}.ilike.%${searchKeyword}%`,
      `${taptapCustomerField.LAST_NAME}.ilike.%${searchKeyword}%`,
      `${taptapCustomerField.INTERNAL_TRANSACTION_NO}.ilike.%${searchKeyword}%`
    ].join(',');

    query = query.or(conditions);
  }

  if (logic === 'and') {
    conditions.forEach((filter) => {
      const field = filter?.field?.toLowerCase().replace(/\s/g, '_');
      const operator = filter?.operator;
      const value = filter?.value;

      switch (operator) {
        // case 'eq':
        //   query = query.eq(field, value);
        //   break;
        // case 'neq':
        //   query = query.neq(field, value);
        //   break;
        // case 'is':
        //   query = query.is(field, value);
        //   break;
        // case 'like':
        //   query = query.like(field, `%${value}%`);
        //   break;
        case 'in':
          query = query.in(field, value);
          break;
        default:
          console.error(`Unsupported operator: ${operator}`);
      }
    });
  }

  if (sortingPayload && sortingPayload?.length > 0) {
    sortingPayload.forEach((sort) => {
      query = query.order(sort.field, {
        ascending: sort.direction === 'ASC',
        referencedTable: sort.referencedTable || undefined
      });
    });
  }

  if (currentPage === 1) {
    console.log('First Page');
    // First Page
    const offset = (currentPage - 1) * pageSize;
    const endRange = offset + pageSize - 1;

    query = query.range(offset, endRange);
  } else if (currentPage > 1) {
    console.log('Next Pages');
    // Next Pages
    const offset = (currentPage - 1) * pageSize;
    const endRange = Math.min(maxRecordCount - 1, offset + pageSize - 1);

    query = query.range(offset, endRange);
  }

  return query;
};

export const taptapFetchCustomersThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapCustomerData[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapFetchCustomersThunk', async (_, thunkApi) => {
  const filterPayload = thunkApi.getState().taptapCustomer
    .taptapGetCustomersPayload;

  const response = await getCustomersQueryBuilder({ payload: filterPayload });

  if (response.error) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  return response;
});

export const taptapFetchCustomersNextPageThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapCustomerData[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapFetchCustomersNextPageThunk', async (_, thunkApi) => {
  const lastPageReached = thunkApi.getState().taptapCustomer.lastPageReached;
  if (lastPageReached) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  const filterPayload = thunkApi.getState().taptapCustomer
    .taptapGetCustomersPayload;
  const clonedFilterPayload = cloneDeep(filterPayload); // cloned becase mutation is not allowed and process wont proceed

  clonedFilterPayload.pagination.page = clonedFilterPayload.pagination.page + 1; // Syempre next page nga eh heheh
  const maxRecordCount = thunkApi.getState().taptapCustomer
    .taptapCustomerMaxCount;

  const response = await getCustomersQueryBuilder({
    payload: clonedFilterPayload,
    maxRecordCount
  });

  return response;
});

export const taptapGetProfilesThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapProfile[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapGetProfilesThunk', async () => {
  const response = await taptapGetProfiles();
  if (response.error) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  return response;
});
